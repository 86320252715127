import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import SearchIcon from "@material-ui/icons/Search";
import { InputAdornment, TextField } from '@material-ui/core';
import api from '../../services/api';
import toastError from '../../errors/toastError';
import { i18n } from '../../translate/i18n';
import useTickets from '../../hooks/useTickets';


const ForwardMessageMenu = ({ handleClose, open, message }) => {
  const [searchParam, setSearchParam] = useState("")
  // eslint-disable-next-line
  const [pageNumber, setPageNumber] = useState(1)
  
  const handleListItemClick = (ticketId) => {
    sendMessage(ticketId)
    handleClose()
  };

  const sendMessage = async (tiketId) => {
    const messageToSend = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: "",
      forwardedMsg: message,
    };
    try {
      await api.post(`/messages/${tiketId}`, messageToSend);
    } catch (err) {
      toastError(err);
    }
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const { tickets } = useTickets({
    pageNumber,
    searchParam,
    showAll: true,
  });

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth>
      <DialogTitle id="simple-dialog-title">Encaminhar para...</DialogTitle>
      <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
      <List>
        {tickets.map((ticket) => (
          <ListItem button onClick={() => handleListItemClick(ticket.id)} key={ticket.id}>
            <ListItemAvatar>
              <Avatar src={ticket.contact.profilePicUrl} />
            </ListItemAvatar>
            <ListItemText primary={ticket.contact.name} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

export default ForwardMessageMenu;