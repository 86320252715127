import React, { useState, useEffect } from "react";
import Board from 'react-trello';

import toastError from "../../errors/toastError";
import api from "../../services/api";
// import { i18n } from "../../translate/i18n";

const components = {
    // GlobalStyle: MyGlobalStyle, // global style created with method `createGlobalStyle` of `styled-components`
    // LaneHeader: MyLaneHeader,
    // Card: MyCard,
    // AddCardLink: MyAddCardLink,
};

const data = {
    lanes: [
      {
        id: 'lane1',
        title: 'Planned Tasks',
        cards: [
            {
              description: 'Thanks. Please schedule me for an estimate on Monday.',
              id: 'Card1',
              laneId: 'lane1',
              title: 'John Smith',
              style: {
                backgroundColor: '#eec'
              },
            },
            {
              description: 'Email received at 1:14pm',
              id: 'Card2',
              laneId: 'lane1',
              title: 'Card Weathers'
            }
        ]
      },
      {
        id: 'lane2',
        title: 'Work In Progress',
        cards: [
            {
              description: 'You are welcome. Interested in doing business with you again',
              id: 'Card3',
              laneId: 'lane2',
              tags: [
                {
                  bgcolor: 'red',
                  color: 'white',
                  title: 'Critical'
                },
                {
                  bgcolor: '#0079BF',
                  color: 'white',
                  title: '2d ETA'
                }
              ],
              title: 'Michael Caine'
            }
        ]
      },
      {
        id: 'lane3',
        title: 'Completed Tasks',
        cards: [
            {
              description: 'You are welcome. Interested in doing business with you again',
              id: 'Card3',
              laneId: 'lane2',
              tags: [
                {
                  bgcolor: 'red',
                  color: 'white',
                  title: 'Critical'
                },
                {
                  bgcolor: '#0079BF',
                  color: 'white',
                  title: '2d ETA'
                }
              ],
              title: 'Michael Caine'
            }
        ]
      }
    ]
}

// import { useParams } from "react-router-dom";

// const useStyles = makeStyles((theme) => ({
//   chatContainer: {
//     flex: 1,
//     padding: 16,
//     height: `calc(100% - 48px)`,
//     overflowY: "hidden",
//   }
// }));

const Task = () => {

    const [loading, setLoading] = useState(true);


    useEffect(() => {
        console.log(333)
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            
          const fetchTicket = async () => {
            try {
              const { data } = await api.get("/tickets/" + 1);
              console.log(data);
    
        //       setContact(data.contact);
        //       setTicket(data);
              setLoading(false);

            } catch (err) {
              setLoading(false);
              toastError(err);
            }
          };
          fetchTicket();

        }, 500);

        return () => clearTimeout(delayDebounceFn);

    }, []);


//   const classes = useStyles();
//   const { ticketId } = useParams();

  return (
    
    <Board loading={loading} data={data} components={components} style={{backgroundColor: '#CCC'}}/>
    
    // <div className={classes.chatContainer}>
    //   <div className={classes.chatPapper}>
    //     <Grid container spacing={0}>
    //       {/* <Grid item xs={4} className={classes.contactsWrapper}> */}
    //       <Grid
    //         item
    //         xs={12}
    //         md={4}
    //         className={
    //           ticketId ? classes.contactsWrapperSmall : classes.contactsWrapper
    //         }
    //       >
    //         <TicketsManager />
    //       </Grid>
    //       <Grid item xs={12} md={8} className={classes.messagessWrapper}>
    //         {/* <Grid item xs={8} className={classes.messagessWrapper}> */}
    //         {ticketId ? (
    //           <>
    //             <Ticket />
    //           </>
    //         ) : (
    //           <Hidden only={["sm", "xs"]}>
    //             <Paper className={classes.welcomeMsg}>
    //               {/* <Paper square variant="outlined" className={classes.welcomeMsg}> */}
    //               <span>{i18n.t("chat.noTicketMessage")}</span>
    //             </Paper>
    //           </Hidden>
    //         )}
    //       </Grid>
    //     </Grid>
    //   </div>
    // </div>
  );
};

export default Task;
