import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import LinearBuffer from "../../components/LinearBuffer";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import PreviewMessage from "../../components/PreviewMessage";
import SelectConection from "../../components/SelectConection";
import Title from "../../components/Title";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";




const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },

  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  btnWrapper: {
    position: "relative",
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ContactSchema = Yup.object().shape({
  csv: Yup.string().required("Required"),
  model1: Yup.string().required("O modelo 1 é obrigatório"),
  model2: Yup.string().required("O modelo 2 é obrigatório"),
  model3: Yup.string().required("O modelo 3 é obrigatório"),
});

export const RenderForm = ({
  classes,
  contact,
  handleSaveContact,
  selectedQueueIds,
  setSelectedQueueIds,
  progressMessage,
  handlePreview,
  handleValidateFields
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const fetchContacts = async (setFieldValue) => {
    try {
      const response = await api.get('/contacts');
      const { contacts } = response.data;
      const numbers = contacts.map(contact => contact.number);
      setFieldValue('csv', numbers.join('\n'));
    } catch (error) {
      console.error('Erro ao buscar contatos:', error);
    }
  };

  return (
    <div className={classes.root}>
      <Formik
        initialValues={contact}
        enableReinitialize={true}
        validationSchema={ContactSchema}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            handleSaveContact(values, actions);
          }, 400);
        }}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form>
            <div>
              <SelectConection
                selectedQueueIds={selectedQueueIds}
                onChange={(selectedIds) => setSelectedQueueIds(selectedIds)}
              />
              
                <Tabs
                  value={currentTab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab label="Modelo 1" />
                  <Tab label="Modelo 2" />
                  <Tab label="Modelo 3" />
                  <p><strong>Obs:</strong> Adicione a mensagem em <strong>todos</strong> os modelos!</p>
                </Tabs>

              {[1, 2, 3].map((modelNumber) => (
                <div 
                  key={modelNumber}
                  role="tabpanel" 
                  hidden={currentTab !== modelNumber - 1} 
                >
                  <Field
                    as={TextField}
                    label={`Modelo ${modelNumber}`}
                    type={`model${modelNumber}`}
                    multiline
                    minRows={10}
                    fullWidth
                    name={`model${modelNumber}`}
                    error={touched[`model${modelNumber}`] && Boolean(errors[`model${modelNumber}`])}
                    helperText={touched[`model${modelNumber}`] && errors[`model${modelNumber}`]}
                    variant="outlined"
                    margin="dense"
                  />
                </div>
              ))} 

              <Field
                as={TextField}
                label="Contatos"
                type="csv"
                multiline
                minRows={5}
                fullWidth
                name="csv"
                error={touched.csv && Boolean(errors.csv)}
                helperText={touched.csv && errors.csv}
                variant="outlined"
                margin="dense"
                onChange={(e) => setFieldValue('csv', e.target.value)}
                value={values.csv}
              />

              <LinearBuffer progressMessage={progressMessage} />
            </div>
            <div>
              <Button
                variant="contained"
                color="secondary"
                disabled={isSubmitting}
                onClick={() => {handlePreview(values)}} 
                className={classes.btnWrapper}
              >
                Mostrar preview
              </Button>

              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting}
                variant="contained"
                className={classes.btnWrapper}
                onClick={() => handleValidateFields(values)}
              >
                Enviar Mensagem
                {isSubmitting && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>

              <Button
                variant="contained"
                color="inherit"
                disabled={isSubmitting}
                onClick={() => fetchContacts(setFieldValue)}
                className={classes.btnWrapper}
              >
                Adicionar Contatos
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const Contacts = () => {
  const classes = useStyles();
  const isMounted = useRef(true);
  const [selectedQueueIds, setSelectedQueueIds] = useState('');
  const [progressMessage, setProgressMessage] = useState(0);
  // eslint-disable-next-line
  const [startSendMessage, setStartSendMessage ] = useState(false);
  const [message, setMessage] = useState([]);
  const initialState = {
    csv: "",
    model: "",
  };

  const [contact, setContact] = useState(initialState);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleClose = () => {
    setContact(initialState);
    setProgressMessage(0);
  };

  const formatNumber = (number) => {
    if(!number) toastError("Número inválido");
    if (number.length > 11 && number.startsWith("55")) {
      return `${number}`;
    }else {
      return `55${number}`;
    }
  };

  const handleValidateFields = async (values) => {
    let { csv, model1, model2, model3 } = values;
  
    const models = [model1, model2, model3];
    const emptyModelFields = models.findIndex(model => !model);
    
    if (!selectedQueueIds) {
      toast.error("Selecione uma conexão");
    } else
    if (emptyModelFields !== -1) {
      toast.error(`O Modelo ${emptyModelFields + 1} é obrigatório!`);
    } else
    if (!csv) {
      toast.error("Adicione os contatos!");
    }
  }

  const handleSaveContact = async (values, actions) => {
    try {
      let { csv, model1, model2, model3 } = values;
  
      const models = [model1, model2, model3]; // Array dos modelos de mensagens
  
      const csvRows = csv.split("\n");
      const csvHeader = ["header"];
      const csvData = csvRows.slice(0);
  
      const array = csvData.map((row) => {
        const values = row.split(",");
        const obj = csvHeader.reduce((object, header, index) => {
          object[header] = values[index];
          return object;
        }, {});
        return obj;
      });
  
      let listMessage = [];
  
      array.forEach((value) => {
        const randomModelIndex = Math.floor(Math.random() * models.length);
        const selectedModel = models[randomModelIndex];
        const message = selectedModel.replace(/\{([^}]+)\}/g, (match, key) => value[key]);
  
        const getValueObject = Object.values(value);
        listMessage.push({
          message: message,
          phone: formatNumber(getValueObject[0]),
          whatsappId: selectedQueueIds,
        });
      });
  
      try {
        console.log(listMessage);
        await api.post("/bulkMessage", listMessage);
        handleClose();
        actions.setSubmitting(false);
        toast.success(`${listMessage.length} Mensagem salva no banco de dados com sucesso`);
      } catch (error) {
        toastError(error);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handlePreview = async (values) => {
    if (!values.model1) {
      toast.error(`Os Modelos são obrigatórios!`);
      return;
    } else
    if (!values.csv) {
      toast.error("Adicione os contatos!");
      return;
    }

    try {
      let { csv, model1 } = values;
      
      const csvRows = csv.split("\n");
      const csvHeader = ["header"];
      const csvData = csvRows.slice(0);

      const array = csvData.map((row) => {
        const values = row.split(",");
        const obj = csvHeader.reduce((object, header, index) => {
          object[header] = values[index];
          return object;
        }, {});
        return obj;
      });

      let rawMsg = [];
      let listMessage = [];

      array.forEach((value, index) => {
        rawMsg.push(model1.replace(/\{([^}]+)\}/g, (match, key) => value[key]));
      });


      array.forEach(async (value, index) => {
        const getValueObject = Object.values(value);
        listMessage.push({
          message: rawMsg[index],
          phone: formatNumber(getValueObject[0]),
          whatsappId: selectedQueueIds,
        });
      });

      setMessage(listMessage);
    } catch (err) {
      toastError(err);
    }
  };


  return (
    <MainContainer className={classes.mainContainer}>
      <MainHeader>
        <Title>Envio de Mensagem</Title>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <RenderForm
          classes={classes}
          contact={contact}
          setContact={setContact}
          selectedQueueIds={selectedQueueIds}
          setSelectedQueueIds={setSelectedQueueIds}
          progressMessage={progressMessage}
          setProgressMessage={setProgressMessage}
          startSendMessage={startSendMessage}
          handleSaveContact={handleSaveContact}
          handlePreview={handlePreview}
          handleValidateFields={handleValidateFields}
        />

        <div>
          {message.length > 0 && (
            <PreviewMessage
              message={message}
             
            />
          )}
        </div>
      </Paper>
    </MainContainer>
  );
};

export default Contacts;
